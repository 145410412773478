<template>
  <div>
    <v-card width="100%" elevation="3" class="px-9 pt-10 pb-14 mb-12" v-if="site_config.debt_page">
      <v-card-title style="font-size: 2rem; line-height: 2rem; color: #616161" class="py-2">
        {{ site_config.debt_page.title }}
      </v-card-title>
      <v-card-text class="pt-3 pb-2" style="font-size: 1.2rem; line-height: 1.8rem; width: 80%" v-if="site_config.debt_page.text">
        {{ site_config.debt_page.text }}
      </v-card-text>
    </v-card>
    <v-list class="pa-0 ma-0" dense>
      <v-list-item class="px-0 grey elevation-1 lighten-4">
        <v-list-item-content class="d-flex align-center" style="font-size: 0.9rem; font-weight: 500">
          <div :style="$vuetify.breakpoint.xs ? 'flex: 1 1 100%; padding-bottom: 8px' : 'flex: 1'" class="pl-7">
            Адреса
          </div>
          <div :style="$vuetify.breakpoint.xs ? 'flex: 1 1 33%' : 'flex:  0 0 150px'">
            Остання <br> дата оплати
          </div>
          <div :style="$vuetify.breakpoint.xs ? 'flex: 1 1 33%' : 'flex:  0 0 120px'">
            Термін <br> (міс.)
          </div>
          <div style="text-align: right; padding-right: 10px" :style="$vuetify.breakpoint.xs ? 'flex: 1 1 33%' : 'flex:  0 0 120px'">
            Сума <br> (грн.)
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          class="px-0 my-2 elevation-1" :style="`border-left: 5px solid ${accent_color_hash}`"
          v-for="(item, idx) in items" :key="idx"
      >
        <v-list-item-content class="d-flex align-center" style="font-size: .96rem">
          <div :style="$vuetify.breakpoint.xs ? 'flex: 1 1 100%; padding-bottom: 8px' : 'flex: 1'" class="pl-6">
            {{ item.address }}
          </div>
          <div :style="$vuetify.breakpoint.xs ? 'flex: 1 1 33%' : 'flex:  0 0 150px'">
            {{ item.pay_date | formatDate }}
          </div>
          <div :style="$vuetify.breakpoint.xs ? 'flex: 1 1 33%' : 'flex:  0 0 120px'">
            {{ item.debt_month }}
          </div>
          <div style="text-align: right; padding-right: 10px" :style="$vuetify.breakpoint.xs ? 'flex: 1 1 33%' : 'flex:  0 0 120px'">
            {{ item.end_balance | formatNumber }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import siteAPI from "@/utils/axios/personalSite/site"
import {mapGetters} from "vuex";
import {check_show_page} from "@/utils/icons";

export default {
  name: "Documents",
  computed: {
    ...mapGetters({
      site_config: 'getSiteConfig'
    }),
    accent_color() {
      return this.site_config.site_color || 'secondary'
    },
    accent_color_hash() {
      return this.site_config.site_color_hash || '#f18a0d'
    }
  },
  data() {
    return {
      items: []
    }
  },
  created() {
    const check = check_show_page(this.$route.name, this.site_config)
    if (!check) {
      this.$router.push({ name: 'SiteBlocked' })
    }

    const organization_slug = this.$route.params.organization || ''
    if (organization_slug) {
      siteAPI.get_top_debt(organization_slug)
          .then(response => response.data)
          .then(data => {
            this.items = data
          })
          .catch(() => {
            this.$router.push({ name: 'NotFoundSite' })
          })
    }
  }
}
</script>

<style scoped>

</style>